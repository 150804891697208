<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Actas en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Estatus, Digitalización y Traslado del Acta PREP
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Estatus, Digitalización y Traslado del Acta PREP
          </h2>

          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-12 text-center">
              <div _ngcontent-qrr-c69="" class="col-lg-12 video">
                <b-embed
                  type="video"
                  aspect="16by9"
                  controls
                >
                  <source
                    src="https://ayuda.ine.mx/2024/informate/assets/video/Estatus_Digitalizacion_Traslado_del_Acta_PREP.mp4"
                    type="video/mp4"
                  >
                </b-embed>
              </div>
            </div>
          </div>

          <b-tabs content-class="my-4">
            <b-tab title="Estatus">
              <div  class="row">
                <div  class="col-lg-12 text-center">
                  <h2  class="p-t-50"> Estatus </h2>
                  <p  class="f-s-24"> Durante la operación del PREP un Acta puede tener los siguientes estatus:&nbsp; </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-4 imagenes-60 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-1.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Esperadas </h2>
                  <p  class="f-s-24"> Número de Actas de todas las casillas aprobadas por el Consejo Distrital respectivo, por cada tipo de elección.​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-2.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Acopiadas </h2>
                  <p  class="f-s-24"> Total de Actas recibidas en el Centro de Acopio y Transmisión de Datos (CATD).​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-3.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Digitalizadas </h2>
                  <p  class="f-s-24"> Actas cuya imagen ha sido capturada digitalmente con la aplicación PREP Casilla o mediante escáner, garantizando su legibilidad.​ </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-4 imagenes-60 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-4.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Capturadas </h2>
                  <p  class="f-s-24"> Actas registradas en el sistema informático que se encuentran dentro del catálogo de Actas esperadas. ​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-5.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub m-t-20"> Verificadas </h2>
                  <p  class="f-s-24"> Actas capturadas, cuyos datos se compararon con la imagen correspondiente.​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50">
                  <div  class="text-center"><img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-6.svg" class="rsp-pb50 text-center"></div>
                  <h2  class="f-s-sub text-center"> Contabilizadas </h2>
                  <ul >
                    <li > Actas de casillas aprobadas.​ </li>
                    <li > Contienen al menos un dato que es posible sumar.​ </li>
                    <li > El total de votos no excede la Lista Nominal de la casilla.​ </li>
                  </ul>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-4 imagenes-60">
                  <div  class="text-center"><img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-7.svg" class="rsp-pb50 text-center"></div>
                  <h2  class="f-s-sub text-center"> No contabilizadas​ </h2>
                  <ul >
                    <li > Presentan una omisión, ilegibilidad o error en los campos de identificación del Acta PREP. ​ </li>
                    <li > Todos los campos en los que se asientan votos no contienen dato alguno o son ilegibles.​ </li>
                    <li > El total de votos excede la Lista Nominal de la casilla </li>
                  </ul>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-8.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub m-t-20"> Publicadas​ </h2>
                  <p  class="f-s-24"> Actas cuyos datos e imágenes pueden consultarse en el sitio PREP. ​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-9.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Cotejadas ​ </h2>
                  <p  class="f-s-24"> Actas digitalizadas cuyos datos publicados fueron corroborados y coinciden con los datos asentados en el Acta original. ​ </p>
                </div>
              </div>
              <div  class="row m-t-20 b-color-gris">
                <div  class="offset-lg-2 col-lg-8 imagenes-30 text-center p-t-20 p-b-20">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/estatus-10.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Fuera de catálogo​ </h2>
                  <p  class="f-s-24"> Actas registradas en el sistema, sus datos de identificación no permiten asociarlas a una casilla aprobada, por lo que se encuentran fuera de las Actas esperadas. </p>
                </div>
              </div>
              <div  class="row m-b-50">
                <div  class="col-lg-12 text-center f-s-16">
                  <p  class="f-w-500"> Referencias: </p>
                  <p  class="referencias"> · Numeral 26 del Anexo 13 del Reglamento de Elecciones del Instituto Nacional Electoral, relativo a los Lineamientos del PREP. </p>
                </div>
              </div>
              <div  class="row p-contenedor-30 m-t-50 m-b-50">
                <div  class="col-lg-12 text-center">
                    <a
                      href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Estatus_del_Acta_PREP.pdf"
                      target="_blank"
                      class="btn btn-primary mx-auto d-block"
                    >
                      Descarga Infografía
                    </a>
                </div>
              </div>
            </b-tab>
            <b-tab title="Digitalización">
              <div  class="row">
                <div  class="col-lg-12 text-center">
                  <h2  class="p-t-50"> Digitalización </h2>
                  <p  class="f-s-24"> A través del PREP puedes ver la imagen del Acta con los resultados de tu casilla, firmada por el funcionariado de casilla y por las representaciones de los Partidos Políticos y Candidaturas Independientes.​ </p>
                </div>
              </div>
              <div  class="row">
                <div  class="offset-lg-2 col-lg-8 imagenes-30 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/digit-1.svg" class="img-fluid mx-auto d-block">
                  <p  class="f-s-24"> Al cierre de la votación, a las 18:00 horas, una vez que el funcionariado de casilla llenó y firmó las Actas, éstas son digitalizadas mediante alguna de las siguientes dos formas:​ </p>
                </div>
              </div>
              <div  class="row">
                <div  class="col-lg-6 text-center imagenes-50">
                  <h2  class="f-s-sub"> Digitalización desde casilla </h2>
                  <p  class="f-s-24"> La o el Capacitador Asistente Electoral (CAE), captura la imagen del Acta de Escrutinio y Cómputo (AEC) mediante la aplicación PREP Casilla. </p>
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/digit-2.svg" class="img-fluid mx-auto d-block">
                </div>
                <div  class="col-lg-6 text-center imagenes-50">
                  <h2  class="f-s-sub"> Digitalización en Centros de Acopio y Transmisión de Datos (CATD)​ </h2>
                  <p  class="f-s-24"> Personal del PREP digitaliza con un escáner. </p>
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/digit-3.svg" class="img-fluid mx-auto d-block">
                </div>
              </div>
              <div  class="row">
                <div  class="offset-lg-2 col-lg-8 imagenes-30 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/digit-4.svg" class="img-fluid mx-auto d-block">
                  <p  class="f-s-24"> Las imágenes son enviadas al Monitor de Captura de Actas Digitalizadas (MCAD), para verificar su legibilidad y proceder a su captura de los datos de las Actas. </p>
                </div>
              </div>
              <div  class="row m-b-50 m-t-50">
                <div  class="col-lg-12 text-center f-s-16">
                  <p  class="f-w-500"> Referencias: </p>
                  <p  class="referencias"> · Numeral 15 del Anexo 13 del Reglamento de Elecciones del Instituto Nacional Electoral, relativo a los Lineamientos del PREP. </p>
                  <p  class="referencias"> · Acuerdo INE/CG546/2020 del Consejo General del Instituto Nacional Electoral por el que se aprueba: El Proceso Técnico Operativo y Consideraciones Generales para la Operación del Programa de Resultados Electorales Preliminares para El Proceso Electoral Federal 2020- 2021​. </p>
                  <p  class="referencias"> · Numerales 4, inciso C y 22 a 28 del Proceso Técnico Operativo del Programa de Resultados Electorales Preliminares para el Proceso Electoral Federal 2023 - 2024. </p>
                </div>
              </div>
              <div  class="row p-contenedor-30 m-t-50 m-b-50">
                <div  class="col-lg-12 text-center">
                    <a
                      href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Digitalizacion_del_Acta_PREP.pdf"
                      target="_blank"
                      class="btn btn-primary mx-auto d-block"
                    >
                      Descarga Infografía
                    </a>
                </div>
              </div>
            </b-tab>
            <b-tab title="Traslado">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h2 class="p-t-50"> Traslado </h2>
                  <p  class="f-s-24"> Una vez concluido el escrutinio y cómputo de votos, las presidencias de las Mesas Directivas de Casilla se pueden apoyar en alguno de los siguientes mecanismos de traslado: </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-4 imagenes-60 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-1.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Centro de Recepción y Traslado Fijo (CRyT Fijo) </h2>
                  <p  class="f-s-24"> Las presidencias de las Mesas Directivas de Casilla, trasladan el paquete electoral al CRyT Fijo, donde son concentrados para ser trasladados a los Consejos Distritales.​ </p>
                </div>
                <div  class="col-lg-4 imagenes-50 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-2.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub"> Centro de Recepción y Traslado Itinerante (CRyT Itinerante) </h2>
                  <p  class="f-s-24"> El funcionariado de los CRyT Itinerantes, recolectan los paquetes electorales programados conforme a la ruta determinada para su traslado a un CRyT Fijo o al Consejo Distrital. ​ </p>
                </div>
                <div  class="col-lg-4 imagenes-60 text-center">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-3.svg" class="img-fluid mx-auto d-block">
                  <h2  class="f-s-sub m-t-20"> Dispositivo de apoyo para el Traslado (DAT) </h2>
                  <p  class="f-s-24"> Las presidencias de las Mesas Directivas de Casilla son transportadas junto con el Paquete Electoral para realizar su entrega en la sede del Consejo Distrital o en el Centro de Recepción y Traslado Fijo (CRyT Fijo). ​ </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-12 col-12">
                  <p  class="f-s-24"> Asimismo, el funcionariado de casilla puede hacer el Traslado Directo de los paquetes electorales al órgano electoral correspondiente, siempre y cuando esté previsto en los mecanismos de recolección, una vez que han transcurrido todas las actividades en las casillas a su cargo y éstas han sido clausuradas. </p>
                  <p  class="f-s-24"> Las representaciones de los Partidos Políticos y Candidaturas Independientes registrados previamente, pueden acompañar los paquetes electorales en cualquiera de sus mecanismos o en el Traslado Directo.​ </p>
                </div>
              </div>
              <div  class="row m-t-20 b-color-gris">
                <div  class="col-lg-6 text-center p-b-20 p-t-20 imagenes-60">
                  <img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-4.svg" class="img-fluid mx-auto d-block">
                  <p  class="f-s-24 text-dark"> Los paquetes electorales se reciben en los Consejos Distritales para que el miércoles siguiente al día de la jornada electoral se realicen los cómputos distritales.​​ </p>
                </div>
              </div>
              <div  class="row b-color-gris">
                <div  class="col-lg-12 imagenes-100 text-center p-b-20 p-t-20 d-lg-block d-none"><img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-5.svg" class="img-fluid mx-auto d-block"></div>
                <div  class="col-lg-12 imagenes-100 text-center p-b-20 p-t-20 d-lg-none d-block"><img  src="https://ayuda.ine.mx/2024/informate/assets/img/prep/traslado-5-movil.svg" class="img-fluid mx-auto d-block"></div>
              </div>
              <div  class="row m-b-50 m-t-50">
                <div  class="col-lg-12 text-center f-s-16">
                  <p  class="f-w-500"> Referencias: </p>
                  <p  class="referencias"> · Artículo 329 del Reglamento de Elecciones del Instituto Nacional Electoral, relativo a los Lineamientos del PREP.​ </p>
                </div>
              </div>

              <div  class="row p-contenedor-30 m-t-50 m-b-50">
                <div  class="col-lg-12 text-center">
                    <a
                      href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Traslado_del_Acta_PREP.pdf"
                      target="_blank"
                      class="btn btn-primary mx-auto d-block"
                    >
                      Descarga Infografía
                    </a>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}

.btn {
  max-width: 350px;
  padding: 1.3rem;
  border-radius: 15px;
  border-color: transparent;
  background-color: #582D73;
  box-shadow: transparent;
}
</style>
